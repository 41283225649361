<template>
  <div class="verify">
    <div id="imgBox">
      <!-- 第一行 -->
      <div class="item-box">
        <div class="line"></div>
        <div class="circle-big">
          <div class="circle-small circle-small-first">
            <div class="mark"></div>
          </div>
        </div>

        <div class="text-box">
          <div>
            <div
              class="title-weight"
              v-html="verify.step1.title"
            ></div>
            <div
              class="describe"
              v-html="verify.step1.describe"
            ></div>
          </div>
          <!-- <div class="qrcode-box">
          </div> -->
        </div>
      </div>
      <!-- 第二行 -->
      <div class="item-box">
        <div class="line"></div>
        <div class="circle-big">
          <div class="circle-small">
            <div class="mark"></div>
          </div>
        </div>

        <div class="text-box">
          <div
            class="title-weight"
            v-html="verify.step2.title"
          ></div>
          <div class="qrcode-box">
            <img
              :src="verify.step2.qrcode_img"
              alt=""
            >
          </div>
        </div>
      </div>
      <!-- 第三行 -->
      <div class="item-box">
        <div class="line"></div>
        <div class="circle-big">
          <div class="circle-small">
            <div class="mark"></div>
          </div>
        </div>

        <div class="text-box">
          <div
            class="title-weight"
            v-html="verify.step3.title"
          ></div>
          <!-- <div class="qrcode-box">
          </div> -->
          <div class="icons-box">
            <div>
              <div class="icon icon1">
                <img
                  :src="verify.step3.imgList[0]"
                  alt=""
                >
              </div>
              <div>答疑互动</div>
            </div>
            <div>
              <div class="icon icon2">
                <img
                  :src="verify.step3.imgList[1]?verify.step3.imgList[1]:verify.step3.imgList[0]"
                  alt=""
                >
              </div>
              <div>课程资料</div>
            </div>
            <div>
              <div class="icon icon3">
                <img
                  :src="verify.step3.imgList[2]?verify.step3.imgList[2]:verify.step3.imgList[0]"
                  alt=""
                >
              </div>
              <div>惊喜福报</div>
            </div>
          </div>
        </div>
      </div>
      <div class="arrow-tip"></div>
      <div class="arrow-tip"></div>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      loading: false,
      verify: {
        step1: {
          title: '恭喜您报名成功',
          describe: '软件测试名企内推直通车<br/>挑选1000名学习精英，挑战名企内推资格'
        },
        step2: {
          title: '加入班级微信群，获取专属服务',
          qrcode_img: 'https://ceshiren.com/uploads/default/original/3X/3/1/3113161a2ea392753b559ba77099ee2135651e82.jpeg'
        },
        step3: {
          title: '微信社群福利',
          imgList: ['https://ceshiren.com/uploads/default/original/3X/3/1/3113161a2ea392753b559ba77099ee2135651e82.jpeg',
            'https://ceshiren.com/uploads/default/original/3X/3/1/3113161a2ea392753b559ba77099ee2135651e82.jpeg',
            'https://ceshiren.com/uploads/default/original/3X/3/1/3113161a2ea392753b559ba77099ee2135651e82.jpeg']
        },
      }
    }
  },
  created () {
    this.getData()
  },
  mounted () {
    // this.$toImage.domtoimg(document.getElementById('imgBox'))
    // this.loading = false
  },
  methods: {
    //获取数据
    getData () {
      this.loading = true
      // axios.get(`${location.origin}/backend/config/${this.$route.query.config}`)
      axios.get(`https://dev.design.ceba.ceshiren.com/backend/config/${this.$route.query.config}`)
        .then(res => {
          console.log(res);
          if (res.data.data) {
            this.verify = res.data.data.verify

            if (res.data.data.css) {
              this.createCssLink(res.data.data.css)
            } else {
              // this.createCssLink('default')
            }
          }

        })
    },
    //避免重复载入 
    removeRemoteCss () {
      const linkList = document.querySelectorAll('#remote_css')
      console.log(linkList);
      for (let i = 0; i < linkList.length; i++) {
        linkList[i].parentNode.removeChild(linkList[i]);
      }
    },

    // 创建link标签引用远程css
    createCssLink (css_name) {
      this.removeRemoteCss()
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.id = 'remote_css'
      const cssPath = `https://dev.design.ceba.ceshiren.com/backend/css/${css_name}.css`
      link.href = cssPath
      document.head.appendChild(link)
      console.log('css加载完成');
      // setTimeout(() => { this.domtoimg() }, 1500)
      setTimeout(() => {
        this.$toImage.domtoimg(document.getElementById('imgBox'),this)
        // this.loading = false
      }, 1500)

    },
  }
}
</script>

<style scoped lang="less">
// .verify {
//   display: flex;
//   justify-content: center;
// }
// #imgBox {
//   width: 100%;
//   max-width: 420px;
//   background-color: #fff;
//   padding-left: 5px;
//   padding-top: 10px;
// }
// .item-box {
//   position: relative;
//   padding-bottom: 10px;
// }
// .circle-big {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   left: -1px;
//   width: 36px;
//   height: 50px;
//   position: absolute;
//   background-color: #fff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .circle-small {
//   width: 32px;
//   height: 32px;
//   /* background-color: #f18d32; */
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #fff;
//   transform: scale(0.7);
//   border: 1px solid #aeaeae;
// }
// .circle-small-first {
//   background-color: #f18d32;
//   border: none;
// }
// .mark {
//   width: 15px;
//   height: 10px;
//   border-left: 2px solid #fff;
//   border-bottom: 2px solid #fff;
//   transform: rotate(-45deg);
//   margin-top: -4px;
//   display: none;
// }

// .line {
//   position: absolute;
//   left: 17px;
//   height: 100%;
//   border-left: 1px solid #aeaeae;
// }
// .text-box {
//   position: relative;
//   top: -3px;
//   word-break: break-all;
//   padding: 15px 10px 0 40px; /* 上右下左的内边距 */
// }
// .title-weight {
//   font-weight: 700;
//   color: #727070;
//   font-family: "FangSong ";
// }
// .text-box .describe {
//   padding: 10px;
//   color: #5c5959;
//   line-height: 1.8;
// }
// .qrcode-box {
//   width: 100px;
//   margin-left: 20px;
// }
// .qrcode-box img {
//   width: 100%;
// }
// .icons-box {
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   padding-top: 13px;
// }
// .icons-box > div {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
// .icons-box .icon {
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: flex-end;
// }
// .icons-box .icon1 {
//   background-color: #ddf3e6;
// }
// .icons-box .icon2 {
//   background-color: #dee6fa;
// }
// .icons-box .icon3 {
//   background-color: #f7dfdb;
// }
// .icons-box .icon img {
//   width: 35px;
// }
// #imgBox > div:first-child .mark {
//   display: block;
// }
// .arrow-tip {
//   width: 10px;
//   height: 10px;
//   border-left: 1px solid #aeaeae;
//   border-bottom: 1px solid #aeaeae;
//   transform: rotate(-45deg);
//   margin-left: 12px;
// }
</style>